<template>
  <div class="container">
    <el-container>
      <!-- 左侧导航 -->
      <!-- <c-left :menuChild="menuChild" v-if="isSideMenu"></c-left> -->
      <c-left :menuChild="menuChild" v-if="$route.path != '/index' && $route.path != '/hotel_index'"></c-left>
      <el-container>
        <!-- 头部 -->
         <el-header height="80px">
            <c-header :menus="menus" :isName="isName" :index="index" @clickMenu="clickMenu"></c-header>
         </el-header>
        <!-- 内容区 -->
        <el-main>
           <keep-alive :include="cachePage || 'fakePage'">
              <router-view name="sidebar"></router-view>
           </keep-alive>
        </el-main>
      </el-container>
    </el-container>
    <!-- 悬浮窗 -->
     <screen v-if="accountType === 'PLATFORM' || accountType === 'TENANT'"></screen>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import cHeader from '@/components/local/Header/Header.vue' // 引入子组件(头部)
import cLeft from '@/components/local/Left.vue' // 引入子组件(左侧导航)
import screen from "@/components/local/screen";
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
export default {
   data(){
      return{
         menus: [],         // 全部菜单列表
         menuChild: [],     // 侧边栏菜单列表
         isSideMenu: false, // 是否显示侧边菜单
         index: '',         // 当前菜单索引值
         isName: true,
         accountType: '',
      }
   },
   components: {
      cHeader, // 注册组件(头部)
      cLeft,    // 注册组件(左侧导航)
      screen
   },
   computed: {
      ...mapState(['cachePage'])
   },
   mounted () {
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo')).accountType
      this.getMenus()
   },
   methods: {
      // 刷新获取菜单列表
      getMenus(menuIndex = ''){
         return new Promise((resolve, reject) => {
            this.index = menuIndex === ''?sessionStorage.getItem('menuIndex'):menuIndex
            this.index === '0' ? this.isName = true : this.isName = false
            const { userName, userId, client, accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
            let url
            if (userName === 'admin' && accountType === 'PLATFORM'){
               url = system.getAllMenuByAdmin + `/${client}`
            }else {
               url = system.getAuthMenuByUser + `/${userId}`
            }
            const param = { client: client }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  let menus = res.records.menus
                  menus = this.filterMenu(menus)
                  menus = this.delChildren(menus)
                  this.menus = this.updateMenuPath(menus)
                  let indexNum = Number(this.index)
                  this.menuChild = this.menus[indexNum].children
                  // 判断是否为首页 首页不显示侧边栏
                  if (indexNum === 0){
                     this.isSideMenu = false
                     return
                  }
                  this.isSideMenu = true
                  resolve(this.menuChild)
               }
            })
         })
      },
      // 修改主菜单路径
      updateMenuPath(menus) {
         menus.forEach((item) => {
            if ('children' in item && item.children.length > 0) {
               if ('children' in item.children[0]) {
                  item.path = item.children[0].children[0].path
               }else {
                  item.path = item.children[0].path
               }
            }
         })
         return menus
      },
      // 递归函数过滤资源类型菜单
      filterMenu(menus){
         menus.forEach((item, index) => {
            if (item.type === 'RESOURCES'){
               menus.splice(index)
            }
            if (item.hasOwnProperty('children')){
               if (item.children !== 0){
                  this.filterMenu(item.children)
               }
            }
         });
         return menus
      },
      // 递归删除空children字段
      delChildren(newMenus){
         newMenus.forEach(item => {
            if (item.hasOwnProperty('children')){
               if (item.children.length === 0){
                  delete item.children
               }
            }
            if (item.hasOwnProperty('children')){
               if (item.children !== 0){
                  this.delChildren(item.children)
               }
            }
         });
         return newMenus
      },
      // 点击获取菜单列表
      async clickMenu(menuIndex){
         await this.getMenus(menuIndex)
         // 设置侧边菜单的初始索引值
         if (this.menuChild.length === 0) return
         if (this.menuChild[0].hasOwnProperty('children')){
            sessionStorage.setItem('sideIndex', '0-0')
            this.$store.dispatch('setMenuNum', '0-0')
            return
         }
         sessionStorage.setItem('sideIndex', '0')
         this.$store.dispatch('setMenuNum', '0')
      }
   }
}
</script>
<style lang="scss">
   .el-main{ padding: 30px }
</style>
