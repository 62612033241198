<template>
   <el-aside width="auto">
      <el-menu
              class="el_item2"
              :default-active="$store.state.menuNum"
              :collapse="$store.state.isCollapse"
              unique-opened>
         <el-row class="pic">
            <p><span class="pic-img"><img  src="../../assets/icon/img.png"></span></p>
         </el-row>
         <template v-for="(item, index) in menuChild">
            <el-menu-item class="menuActive" v-if="!item.children" :key="index" :index="index + ''" @click="clickSidebar(item.path, index + '')">
               <i class="iconfont" :class="[$store.state.isCollapse ? langCn ? '' : 'lang-en' : langCn ? 'lang-cn' : 'lang-en']" v-html="item.icon"></i>
               <span slot="title">{{item.name}}</span>
            </el-menu-item>
            <el-submenu v-if="item.children" :key="index" :index="index + ''" :class="[$store.state.isCollapse ? langCn ? '' : 'aside-en' : langCn ? 'aside-cn' : 'aside-en']">
               <template slot="title">
                  <i class="iconfont" :class="[$store.state.isCollapse ? langCn ? '' : 'lang-en' : langCn ? 'lang-cn' : 'lang-en']" v-html="item.icon"></i>
                  <span slot="title">{{item.name}}</span>
               </template>
               <el-menu-item-group v-for="(value, listIndex) in item.children" :key="listIndex">
                  <el-menu-item v-if="!value.children"
                                :index="index + '-' + listIndex"
                                @click="clickSidebar(value.path, index + '-'+ listIndex)">
                     {{ value.name }}
                  </el-menu-item>
                  <el-submenu v-if="value.children" :index="index + '-' + listIndex">
                     <template slot="title">{{ value.name }}</template>
                     <el-menu-item @click="clickSidebar(row.path, index + '-'+ listIndex + '-' + valIndex)" v-for="(row, valIndex) in value.children" :key="valIndex" :index="index + '-' + listIndex + '-' + valIndex">
                        {{row.name}}
                     </el-menu-item>
                  </el-submenu>
               </el-menu-item-group>
            </el-submenu>
         </template>
      </el-menu>
   </el-aside>
</template>
<script>
export default {
   data () {
      let n = sessionStorage.getItem('sideIndex')
      if (!n) {
         n = '0'
      }
      return {
         username: '',
         login_expired: '',
         langCn: true,
         langEn: false,
         isCollapse: true,
         sideIndex: n,        // 导航高亮参数
      }
   },
   props: {
      menuChild: Array
   },
   watch:{
      $route:{
         handler(val){
            if (val.path==="/pms/order_manage") {
               this.clickSidebar(val.path,"2")
            } else if (val.path == '/pms/abnormal_orders') {
               this.clickSidebar(val.path,"6-2")
            } else if (val.path == '/balance_management') {
               this.clickSidebar(val.path,"5-1")
            }
         },
      }
   },
   mounted () {
      if (localStorage.lang === 'cn') {
         this.langCn = true
         this.langEn = false
      } else {
         this.langCn = false
         this.langEn = true
      }
      this.username = JSON.parse(sessionStorage.getItem('userInfo')).userName
      this.getSideIndex()
   },
   methods: {
      // 获取当前侧边菜单索引值
      getSideIndex(){
         this.sideIndex = sessionStorage.getItem('sideIndex')
         this.$store.dispatch('setMenuNum', this.sideIndex)
      },
      // 侧边栏导航点击跳转
      clickSidebar (url, key) {
         if (!sessionStorage.getItem('router')) {
            this.$message({
               showClose: true,
               message: this.login_expired,
               type: 'error'
            })
            sessionStorage.setItem('router', '/login')
            this.$router.push('/login')
            return
         }
         sessionStorage.setItem('router', url)          // 当前路由
         sessionStorage.setItem('sideIndex', key)       // 侧边栏 当前索引
         this.$store.dispatch('setMenuNum', key)
         this.$router.push(url)
      }
   }
}
</script>
<style lang="scss" scoped>
   .el-aside {
      overflow: overlay;
      .menuActive{ &.is-active{ i{ color: #409eff }  } }
      .el_item2{ width: 210px!important; transition: 0.5s }
      .aside-cn /deep/ .el-menu-item{ padding-left: 75px!important }
      .aside-en /deep/ .el-menu-item{ padding-left: 49px!important }
      .el-menu{
         min-height: 100%;
         border: none;
         > a > li:hover,a .el-menu-item:hover{
            transition: all 0.8s;
            background: #263445 !important;
            color: #409eff !important;
         }
         li {
            .iconfont{ color: white; margin-right: 10px; font-size: 17px }
            .lang-en{ margin-left: 5px }
            .lang-cn{ margin-left: 30px }
         }
         > a:hover .el-menu-item i{
            transition: all 1s; color: #409eff !important;
         }
         .is-active{ color: #409efe }
         .el-menu-item,
         .el-submenu {
            margin-bottom: 5px;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
         }
         .el-menu a,
         .el-menu-item-group ul a{
            text-decoration: none;
         }
      }
      .pic {
         display: flex; justify-content: center;
         flex-direction: column; position: sticky; top: 0; z-index: 1000;
         p {
            text-align: center; width: 100%; overflow: hidden;
            font-weight: 700; background: #087ffd; font-style: normal;
            border-bottom: 1px solid #fff;
            .pic-img {
               display: inline-block; background: #087ffd;
               img{ width: 140px; padding: 5px 0 6px 0 }
            }
         }
      }
      ::v-deep > .el-menu {
         > .el-menu-item{
            font-size: 18px;
            .el-menu-item{
               line-height: 40px; height: 40px;
            }
         }
         > .el-submenu{
            > .el-submenu__title{
               font-size: 18px; height: 60px; line-height: 60px;
            }
            > .el-menu{
               > .el-menu-item-group{
                  ul {
                     > .el-menu-item{
                        font-size: 16px;
                     }
                     > .el-submenu{
                        > .el-submenu__title{
                           font-size: 16px;
                        }
                        .el-menu{
                           .el-menu-item{
                              font-size: 14px;
                           }
                        }
                     }
                  }
               }
            }
         }
         .el-menu-item-group__title{
            display: none;
         }
         > .is-opened{
            > .el-submenu__title{
               background: #087ffd!important;
               color: #FFFFFF;
            }
            .el-menu{
               .is-opened{
                  .el-submenu__title{
                     color: #087ffd!important;
                     background: #ecf5ff;
                  }
                  .el-menu{
                     .el-menu-item{
                        font-size: 12px;
                     }
                  }
               }
            }
         }
      }
   }
</style>
